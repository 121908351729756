import Navigation from "../Home/Navigation";
import CharityProjects from './CharityProjects';
import FinishProjects from './FinishProjects';
import PayInfo from '../PayInfo/PayInfo';

export default () => (
  <div>
    <Navigation>
      <div style={{ width: '100%', height: '100px', backgroundColor: 'black', marginBottom: '100px'}}></div>
    </Navigation>
    <CharityProjects />
    {/* <FinishProjects /> */}
    <PayInfo />
  </div>
);

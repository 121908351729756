import Header from './Header/Header';
import OurActivities from './OurActivities';
import OurValues from './OurValues';
import OurProgress from './OurProgress';
import OurPartners from '../OurPartners/OurPartners';
import AfterVictory from '../AfterVictory/AfterVictory';
import PayInfo from '../PayInfo/PayInfo';

export default () => (
  <div>
    <Header />
    <OurActivities />
    <OurValues />
    <OurProgress />
    <OurPartners />
    <AfterVictory />
    <PayInfo />
  </div>
);
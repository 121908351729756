import styles from './historyFund.module.css';
import globalStyles from '../../../stylesGlobal/globalStyles.module.css';
import SwipeRightIcon from '../../../ui/icons/SwipeRightIcon';
import flag_round from '../../../common/svg/flag_round.png';
import Slider from 'react-slick';
import ButtonSliderLeft from './ButtonSliderLeft';
import ButtonSliderRight from './ButtonSliderRight';
import { useTranslation } from 'react-i18next';

const HistoryFund = () => {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ButtonSliderLeft />,
    prevArrow: <ButtonSliderRight />,
    responsive: [
      {
        breakpoint: 1441,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className={styles.ourTeam}>
      <div className={styles.container}>
        <div>
          <div className={`${globalStyles.title}`}>{t('our_fund_history')}</div>
        </div>
        <div className={styles.block_news}>
          <div className={styles.news_item}>
            <Slider {...settings}>
              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2022</div>
                  <div className={styles.month_text}>{t('history_date_1')}</div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_1')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2022</div>
                  <div className={styles.month_text}>{t('history_date_2')}</div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_2')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2022</div>
                  <div className={styles.month_text}>{t('history_date_3')}</div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_3')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2022</div>
                  <div className={styles.month_text}>{t('history_date_4')}</div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_4')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2023</div>
                  <div className={styles.month_text}>{t('history_date_5')}</div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_5')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2023</div>
                  <div className={styles.month_text}>{t('history_date_6')}</div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_6')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2023</div>
                  <div className={styles.month_text}>{t('history_date_7')}</div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_7')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2023</div>
                  <div className={styles.month_text}>{t('history_date_8')}</div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_8')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2024</div>
                  <div className={styles.month_text}>{t('history_date_9')}</div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_9')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2024</div>
                  <div className={styles.month_text}>
                    {t('history_date_10')}
                  </div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_10')}
                </div>
              </div>

              <div>
                <div className={styles.month_block}>
                  <div className={`${globalStyles.text14B}`}>2024</div>
                  <div className={styles.month_text}>
                    {t('history_date_11')}
                  </div>
                </div>
                <div className={styles.line_container}>
                  <div className={`${globalStyles.round_bg_history}`}>
                    <img
                      className={globalStyles.round_bg_history_img}
                      src={flag_round}
                      alt=""
                    />
                  </div>
                  <div className={styles.line_history}></div>
                </div>
                <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
                  {t('history_text_11')}
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className={styles.swipe_wrapper}>
          <SwipeRightIcon />
          <div className={styles.swipeRight_title}>{t('swipe_left')}</div>
        </div>
      </div>
    </section>
  );
};

export default HistoryFund;

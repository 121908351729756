import styles from "./ourTeam.module.css";
import globalStyles from "../../../stylesGlobal/globalStyles.module.css";
import ButtonSliderLeft from "./ButtonSliderLeft";
import ButtonSliderRight from "./ButtonSliderRight";
import Foto0 from "../../../common/svg/OurTeam/0.png";
import Foto1 from "../../../common/svg/OurTeam/01.png";
import Foto2 from "../../../common/svg/OurTeam/02 dmytryk.png";
import Foto3 from "../../../common/svg/OurTeam/1.png";
import Foto4 from "../../../common/svg/OurTeam/Petrov2.png";
import Foto5 from "../../../common/svg/OurTeam/Kulik3.png";
import Foto6 from "../../../common/svg/OurTeam/kalinina_4.png";
import Foto7 from "../../../common/svg/OurTeam/Dugin_5.png";
import Foto8 from "../../../common/svg/OurTeam/buchatsky_6.png";
import Foto9 from "../../../common/svg/OurTeam/chechenko7.png";
import Foto10 from "../../../common/svg/OurTeam/Tverdostup8.png";
import Foto11 from "../../../common/svg/OurTeam/goshovsky_9.png";
import Foto12 from "../../../common/svg/OurTeam/10.png";
import Foto13 from "../../../common/svg/OurTeam/11.png";
import Foto14 from "../../../common/svg/OurTeam/12_tomlin.png";
import Foto15 from "../../../common/svg/OurTeam/13_konstantinov.png";
import FotoFix from "../../../common/svg/OurTeam/fix.png";
import { useTranslation } from 'react-i18next';

const OurTeam = () => {
  const { t } = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 0,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    centerPadding: "20px",
    rows: 2,
    slidesPerRow: 2,
    variableWidth: true,
    nextArrow: <ButtonSliderLeft />,
    prevArrow: <ButtonSliderRight />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 764,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className={styles.ourTeam}>
      <div className={styles.container}>
        <div>
          <div className={`${globalStyles.title}`}>{t("our_team")}</div>
        </div>
        <div className={styles.block_foto2}>
        </div>
        <div className={styles.block_foto}>
          <div className={styles.main_foto}>
            <div className={styles.border_big}>
              <img className={styles.border_big_img} src={Foto0} alt="" />
              <div
                className={`${globalStyles.text16B} ${styles.text16B_name} `}
              >
                {t("roman")}
              </div>
              <div className={globalStyles.text12Fw4}>{t("founder")}</div>
            </div>
          </div>

          <div className={styles.border_main}>
            <img className={styles.border_main_img} src={Foto1} alt="" />
            <div>
              <div
                className={`${globalStyles.text16B} ${styles.text16B_name}  `}
              >
                {t("volodymyr")}
              </div>
              <div className={globalStyles.text12Fw4}>{t("founder")}</div>
            </div>
          </div>
          {/*<div className={styles.border_main2}>*/}
          {/*  <img className={styles.border_main2_img} src={Foto2} alt="" />*/}
          {/*  <div>*/}
          {/*    <div className={`${globalStyles.text16B} `}>{t("ernest")}</div>*/}
          {/*    <div className={globalStyles.text12Fw4}>{t("manager")}</div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto3} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto4} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto5} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto2} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto6} alt="" />
          </div>
          <div
              className={styles.border_foto}
              style={{
                backgroundimg: `url('../common/svg/OurTeam/fix.png')`,
              }}
          >
            <img className={styles.border_foto_img} src={FotoFix} alt="" />
          </div>


          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto7} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto8} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto9} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto10} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto11} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto12} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto13} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto14} alt="" />
          </div>
          <div className={styles.border_foto}>
            <img className={styles.border_foto_img} src={Foto15} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;

import styles from "./ToBePartnerMain.module.css";
import globalStyles from "../../stylesGlobal/globalStyles.module.css";
import bgImg from "../../common/img/to_be_partners_bg.png";
import HeaderBgIcon from "../../ui/icons/HeaderBgIcon";
import Navigation from "../Home/Navigation";
import { useTranslation } from 'react-i18next';

const ToBePartnerMain = () => {
    const {t} = useTranslation();
    
  return (
      <Navigation>
          <section className={styles.ToBePartner_section}>
              <div className={` ${styles.header_icon}`}>
                  <HeaderBgIcon />
              </div>
              <div className={styles.wrapper}>
                  <div className={` ${styles.header_icon}`}>
                      <HeaderBgIcon />
                  </div>
                  <div className={styles.container}>
                      <div className={styles.block_title}>
                          <div
                              className={`${globalStyles.title_aboutUs} ${styles._title_aboutUs}`}
                          >
                              {t("victory_1")}
                          </div>
                          <span
                              className={`${globalStyles.title_aboutUs} ${styles.title_color}`}
                          >
                        {t("victory_2")}
                      </span>

                          <div className={`${globalStyles.text14Fw4} ${styles.subtitle}`}>
                              {t("victory_3")}
                          </div>
                      </div>
                  </div>
                  <img className={styles.ToBePartner_img} src={bgImg} alt="" />
              </div>

          </section>
      </Navigation>
  );
};

export default ToBePartnerMain;

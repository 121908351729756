import styles from './CharityProjects.module.css';
import globalStyles from '../../stylesGlobal/globalStyles.module.css';
import ButtonToPartner from './ButtonToPartner';
import ButtonFoto from './ButtonFoto';
import { useTranslation } from 'react-i18next';

const CharityProjectsMobile = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={` ${styles.charity_item}`}>
        <div className={` ${styles.charity_left}`}>
          <div className={`${globalStyles.title} ${styles.title_item}`}>
            {t('charity_project_1_header')}
          </div>
          <div className={`${globalStyles.text12Fw4} ${styles._text12Fw4}`}>
            {t('charity_project_1_description')}
          </div>
          <div className={` ${styles.btn_container}`}>
            <div className={` ${styles.btn_wrapper}`}>
              <button className={` ${styles.btn_read}`}>
                {t('read_more')}
              </button>
              <ButtonFoto />
            </div>
            <ButtonToPartner />
          </div>
        </div>
      </div>
      <div className={` ${styles.horizontal_line}`}></div>
      <div className={` ${styles.charity_item}`}>
        <div className={` ${styles.charity_left}`}>
          <div className={`${globalStyles.title} ${styles.title_item_2}`}>
            {t('charity_project_2_header')}
          </div>
          <div className={`${globalStyles.text12Fw4} ${styles.two_text12Fw4}`}>
            {t('charity_project_2_description')}
          </div>
          <div className={` ${styles.btn_container}`}>
            <div className={` ${styles.btn_wrapper}`}>
              <button className={` ${styles.btn_read}`}>
                {t('read_more')}
              </button>
              <ButtonFoto />
            </div>
            <ButtonToPartner />
          </div>
        </div>
      </div>
      <div className={` ${styles.horizontal_line}`}></div>
      <div className={` ${styles.charity_item}`}>
        <div className={` ${styles.charity_left}`}>
          <div className={`${globalStyles.title} ${styles.title_item}`}>
            {t('charity_project_3_header')}
          </div>
          <div className={`${globalStyles.text12Fw4} ${styles._text12Fw4}`}>
            {t('charity_project_3_description')}
          </div>
          <div className={` ${styles.btn_container}`}>
            <div className={` ${styles.btn_wrapper}`}>
              <button className={` ${styles.btn_read}`}>
                {t('read_more')}
              </button>
              <ButtonFoto />
            </div>
            <ButtonToPartner />
          </div>
        </div>
      </div>
      <div className={` ${styles.horizontal_line}`}></div>
      <div className={` ${styles.charity_item}`}>
        <div className={` ${styles.charity_left}`}>
          <div className={`${globalStyles.title} ${styles.title_item}`}>
            {t('charity_project_4_header')}
          </div>
          <div className={`${globalStyles.text12Fw4} ${styles._text12Fw4}`}>
            {t('charity_project_4_description')}
          </div>
          <div className={` ${styles.btn_container}`}>
            <div className={` ${styles.btn_wrapper}`}>
              <button className={` ${styles.btn_read}`}>
                {t('read_more')}
              </button>
              <ButtonFoto />
            </div>
            <ButtonToPartner />
          </div>
        </div>
        <div className={` ${styles.horizontal_line}`}></div>
      </div>
    </>
  );
};

export default CharityProjectsMobile;

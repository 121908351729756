import style from "./ourProgress.module.css";
import TeamImg from "../../common/img/comands.png";

import { useTranslation } from 'react-i18next';

const OurProgress = () => {
  const {t} = useTranslation();
  return (
    <section className={style.section}>
      <div className={style.container}>
        <div className={style.team}>
          <div className={style.title}>{t("team_achievements")}</div>
          {/* <img src={TeamImg} alt="" />
          <button className={style.button}>
            <p className={style.btn_text}>Хочу підтримати</p>
          </button> */}
        </div>

        <div className={style.stats_wraper}>
          <div className={style.stats_card}>
            <div className={style.stats_title}>120+</div>
            <div className={style.stats_subtitle}>
              <span className={style.stats_span}>
                {t("ach_1_1")}
              </span>{" "}
              {t("ach_1_2")}
            </div>
          </div>

          <div>
            <div className={style.stats_title}>
              14<span className={style.stats_title_span}>{t("mln_hrn")}</span>
            </div>
            <div className={style.stats_subtitle}>
              <span className={style.stats_span}>
                {t("ach_2")}
              </span>
            </div>
          </div>

          <div>
            <div className={style.stats_title}>25+</div>
            <div className={style.stats_subtitle}>
              <span className={style.stats_span}>{t("ach_3_1")}</span>
              {t("ach_3_2")}
            </div>
          </div>

          <div>
            <div className={style.stats_title}>50+</div>
            <div className={style.stats_subtitle}>
              <span className={style.stats_span}>
                {t("ach_4_1")}
              </span>{" "}
              {t("ach_4_2")}
            </div>
          </div>
        </div>
        <div className={style.team_footer}>
          <img className={style.team_footer_img} src={TeamImg} alt="" />
          <a href="/support">
            <button className={style.button}>
            <p className={style.btn_text}>{t("whant_to_support")}</p>
          </button></a>
        </div>
      </div>
      <div className={style.bottom_line_img}>
        {/* <img className={style.bottom_line_img} src={bottom_line} alt="svg" /> */}
      </div>
    </section>
  );
};

export default OurProgress;

import styles from "./partnersShip.module.css";
import globalStyles from "../../stylesGlobal/globalStyles.module.css";
import BtnArrowPartnersBlock from "./BtnArrowPartnersBlock";
import bottom_line from "../../common/svg/line_bottom.png";
import { useTranslation } from 'react-i18next';

const PartnersShip = () => {
  const {t} = useTranslation();
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={`${globalStyles.about_block_text}`}>
          {t("partnership_1")}
        </div>
        <div className={`${globalStyles.title} ${styles._title}`}>
          {t("partnership_2")}
        </div>
        <div className={`${globalStyles.text12Fw4} ${styles._text12Fw4}`}>
          {t("partnership_3")}
        </div>

        <div className={styles.block_wraper}>
          <BtnArrowPartnersBlock
            text={t("option_1")}
            width="310px"
          />
          <BtnArrowPartnersBlock
              text={t("option_2")}
            width="420px"
          />
          <BtnArrowPartnersBlock
              text={t("option_3")}
            width="530px"
          />
          <BtnArrowPartnersBlock
              text={t("option_4")}
            width="420px"
          />
          <BtnArrowPartnersBlock
              text={t("option_5")}
              width="420px"
          />
          <BtnArrowPartnersBlock
            className={styles.btn_yellow}
            text={t("option_6")}
            width="310px"
            color="var(--yellow)"
          />
        </div>
      </div>
      <div className={styles.bottom_line_img}></div>
    </section>
  );
};

export default PartnersShip;

import ToBePartnerMain from './ToBePartnerMain';
import AboutFund from './AboutFund';
import PartnersShip from './Partnership';
import ThankesPartners from './ThankesPartners';
import OurPartners from '../OurPartners/OurPartners';
import PayInfo from '../PayInfo/PayInfo';

export default () => (
  <div>
    <ToBePartnerMain />
    <AboutFund />
    <PartnersShip />
    <ThankesPartners />
    <OurPartners />
    <PayInfo />
  </div>
);
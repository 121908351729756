import styles from "./payInfo.module.css";
import globalStyles from "../../stylesGlobal/globalStyles.module.css";

import copyImg from "../../common/svg/copy.svg";
import ButtonMono from "./ButtonMono";
import { useTranslation } from 'react-i18next';
import {copyOnClick} from "../../common/CopyOnClick";

const FormMono = () => {
  const {t} = useTranslation();
  return (
    <div className={` ${styles.requisites_wrapper}`}>
      <div className={`${styles.mono_item}`}>
      <div className={` ${styles.mono_left}`}>
        <div className={`${globalStyles.text18B} ${styles._text18B}`}>
          {t("mono_1_text_1")}
        </div>
        </div>
        <div className={` ${styles.mono_right}`}>
          <div className={`${globalStyles.text10Fw4} ${styles._text10Fw4}`}>
            {t("mono_1_text_2")} 💛💙
          </div>
          <div className={` ${styles.flex_wrapper}`}>
            <div className={` ${styles.card_wrapper}`}>
              <div className={`${globalStyles.text10Fw4} ${styles.text10Fw4}`}>
                {t("mono_1_text_3")}
              </div>
              <div className={`${globalStyles.text14Fw4} ${styles._text14Fw4}`}>
                {" "}
                5375 4112 0448 3437
              </div>
            </div>
            <div
              className={`${globalStyles.round_bg_copy} ${styles.round_mono}`} onClick={() => copyOnClick('5375 4112 0448 3437')}
            >
              <img className={styles.round_bg_img} src={copyImg} alt="svg" />
            </div>
          </div>
          <ButtonMono href="https://send.monobank.ua/jar/7cSCabUq2W" />
        </div>
      </div>
      <div className={`${styles.mono_item}`}>
        <div className={` ${styles.mono_left}`}>
          <div className={`${globalStyles.text18B} ${styles._text18B}`}>
            {t("mono_2_text_1")}
          </div>
        </div>
        <div className={` ${styles.mono_right}`}>
          <div className={`${globalStyles.text10Fw4} ${styles._text10Fw4}`}>
            {t("mono_2_text_2")}
          </div>
          <div className={` ${styles.flex_wrapper}`}>
            <div className={` ${styles.card_wrapper}`}>
              <div className={`${globalStyles.text10Fw4} ${styles.text10Fw4}`}>
                {t("mono_2_text_3")}
              </div>
              <div
                className={`${globalStyles.text14Fw4} ${styles.number_card}`}
              >
                {" "}
                5375 4112 1265 2312
              </div>
            </div>
            <div
              className={`${globalStyles.round_bg_copy} ${styles.round_mono}`} onClick={() => copyOnClick('5375 4112 1265 2312')}
            >
              <img className={styles.round_bg_img} src={copyImg} alt="svg" />
            </div>
          </div>
          <ButtonMono href="https://send.monobank.ua/jar/2ChrKiTWq6" />
        </div>
      </div>
      <div className={`${styles.mono_item}`}>
        <div className={` ${styles.mono_left}`}>
          <div className={`${globalStyles.text18B} ${styles._text18B}`}>
            {t("mono_3_text_1")}
          </div>
        </div>
        <div className={` ${styles.mono_right}`}>
          <div className={`${globalStyles.text10Fw4} ${styles._text10Fw4}`}>
            {t("mono_3_text_2")}
          </div>
          <div className={` ${styles.flex_wrapper}`}>
            <div className={` ${styles.card_wrapper}`}>
              <div className={`${globalStyles.text10Fw4} ${styles.text10Fw4}`}>
                {t("mono_3_text_3")}
              </div>
              <div className={`${globalStyles.text14Fw4} ${styles._text14Fw4}`}>
                {" "}
                5375 4112 1066 5688
              </div>
            </div>
            <div
              className={`${globalStyles.round_bg_copy} ${styles.round_mono}`} onClick={() => copyOnClick('5375 4112 1066 5688')}
            >
              <img className={styles.round_bg_img} src={copyImg} alt="svg" />
            </div>
          </div>
          <ButtonMono href="https://send.monobank.ua/jar/2ZeEg4iHBg" />
        </div>
      </div>
    </div>
  );
};

export default FormMono;

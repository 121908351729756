import styles from "./ApoutUsMain.module.css";
import globalStyles from "../../stylesGlobal/globalStyles.module.css";
import Navigation from "../Home/Navigation";
import { useTranslation } from 'react-i18next';

const AboutUsMain = () => {
  const { t } = useTranslation();

  return (
      <Navigation>
        <section className={styles.aboutUs_section}>
          {/*<img className={styles.aboutUs_img} src={bgImg} alt="" />*/}
          <div className={styles.aboutUs_img}></div>
          <div className={styles.container}>
            <div className={styles.block_title}>
              <div
                  className={`${globalStyles.title_aboutUs} ${styles._title_aboutUs}`}
              >
                  {t("we_have")}
              </div>
              <span
                  className={`${globalStyles.title_aboutUs} ${styles.title_color}`}
              >
            {t("one_goal")}
          </span>
              <div
                  className={`${globalStyles.title_aboutUs} ${styles._title_aboutUs}`}
              >
                  {t("for_everyone")}
              </div>
              <div className={`${globalStyles.text14Fw4} ${styles.subtitle}`}>
                  {t("about_us_text")}
              </div>
            </div>
          </div>
        </section>
      </Navigation>
  );
};

export default AboutUsMain;

import globalStyles from '../../../stylesGlobal/globalStyles.module.css';
import styles from './ButtonStyles.module.css';
import mapImage from '../../../common/svg/prapor 2.png';
// import Image from 'next/image';
// import Link from "next/link";
import React from 'react';
import { useTranslation } from 'react-i18next';

const ButtonToPartner = ({ href, name }) => {
  const { t } = useTranslation();
  const hr = href || '/become_partner';
  const n = name || t('become_a_partner');
  return (
    <a className={`${styles.btn_topartner} ${styles._button}`} href={hr}>
      <div className={`${styles.round_bg}`}>
        <img className={styles.round_bg_img} src={mapImage} alt="svg" />
      </div>
      <span className={`${globalStyles.text14Fw4} ${styles.text14Fw4_btn}`}>
        {n}
      </span>
    </a>
  );
};

export default ButtonToPartner;

import styles from './Header.module.css';
import globalStyles from '../../../stylesGlobal/globalStyles.module.css';
import ButtonToPartner from './ButtonToPartner';
import ButtonDetail from './ButtonDetail';
import banner5 from '../../../common/img/desktop_banner_5.png';
import banner6 from '../../../common/img/desktop_banner_6.png';

import Slider from 'react-slick';
import ButtonSliderLeft from './ButtonSliderLeft';
import ButtonSliderRight from './ButtonSliderRight';
import { useState } from 'react';
// import img from 'next/img';
import Navigation from '../Navigation';
import { useTranslation } from 'react-i18next';
import SwipeRightIcon from '../../../ui/icons/SwipeRightIcon';

const Header = () => {
  const [isNavActive, setIsNavActive] = useState(false);
  const { t } = useTranslation();
  const handleNavClick = () => {
    setIsNavActive(!isNavActive);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ButtonSliderLeft />,
    prevArrow: <ButtonSliderRight />,
  };
  return (
    <Navigation>
      <header className={styles.header}>
        <Slider {...settings}>
          <div>
            <div className={styles.container}>
              <div className={styles.block_left}>
                <div
                  className={`${globalStyles.header_title} ${styles.header_title1}`}
                >
                  {t('dlya_heroiv')}
                </div>
                <div
                  className={`${globalStyles.text16W} ${styles.header_text16B}`}
                >
                  {t('banner_1_text')}
                </div>
              </div>
              <div className={` ${styles.btn_to_partner}`}>
                <ButtonToPartner
                  href={'/become_partner'}
                  name={t('become_a_partner')}
                />
              </div>
              <div className={` ${styles.img_banner1}`}></div>
            </div>
          </div>

          <div>
            <div className={styles.container}>
              <div className={styles.block_left}>
                <div
                  className={`${globalStyles.header_title} ${styles.header_title1}`}
                >
                  {t('dlya_heroiv')}
                </div>

                <div
                  className={`${globalStyles.text16W} ${styles.header_text16B}`}
                >
                  {t('banner_2_text')}
                </div>
              </div>
              <div className={` ${styles.btn_to_partner}`}>
                <ButtonToPartner
                  href={'/become_partner'}
                  name={t('become_a_partner')}
                />
              </div>
              <div className={` ${styles.img_banner2}`}></div>
            </div>
          </div>

          <div>
            <div className={styles.container}>
              <div className={styles.block_left}>
                <div
                  className={`${globalStyles.header_title} ${styles.header_title1}`}
                >
                  {t('dlya_heroiv')}
                </div>

                <div
                  className={`${globalStyles.text16W} ${styles.header_text16B}`}
                >
                  {t('banner_3_text')}
                </div>
              </div>
              <div className={` ${styles.btn_to_partner}`}>
                <ButtonToPartner
                  href={'/become_partner'}
                  name={t('become_a_partner')}
                />
              </div>
              <div className={` ${styles.img_banner3}`}></div>
            </div>
          </div>

          <div>
            <div className={styles.container}>
              <div className={styles.block_left}>
                <div
                  className={`${globalStyles.header_title} ${styles.header_title1}`}
                >
                  {t('dlya_heroiv')}
                </div>

                <div
                  className={`${globalStyles.text16W} ${styles.header_text16B}`}
                >
                  {t('banner_4_text')}
                </div>
              </div>
              <div className={` ${styles.btn_to_partner}`}>
                <ButtonToPartner
                  href={'/become_partner'}
                  name={t('become_a_partner')}
                />
              </div>

              {/* <img
              className={` ${styles.img_banner4}`}
              src={banner4}
              alt="banner_img"
            /> */}
              {/* <div className={` ${styles.header_banner4}`}>


            </div> */}
              <div className={`${styles.img_banner4}`}></div>
            </div>
          </div>

          <div>
            <div className={styles.container}>
              <div className={styles.block_left}>
                <div
                  className={`${globalStyles.header_title} ${styles.header_title_dron}`}
                >
                  {t('drones_for_the_armed_forces')}
                </div>
                <div
                  className={`${globalStyles.header_title} ${styles.header_title2}`}
                ></div>
                <div
                  className={`${globalStyles.text16W} ${styles.header_text16B}`}
                >
                  {t('banner_5_text')}
                </div>
              </div>

              <div className={` ${styles.btn_to_partner}`}>
                <ButtonToPartner href={'/support'} name={t('support')} />
              </div>
              <div className={` ${styles.btn_about}`}>
                <ButtonDetail href={'/projects'} name={t('more')} />
              </div>
              <img
                className={` ${styles.img_banner5}`}
                src={banner5}
                alt="banner_img"
              />
            </div>
          </div>

          <div>
            <div className={styles.container}>
              <div className={styles.block_left}>
                <div
                  className={`${globalStyles.header_title} ${styles.header_title1_3D}`}
                >
                  {t('3d_models')}
                </div>
                {/*<div*/}
                {/*    className={`${globalStyles.header_title} ${styles.header_title1_3D}`}*/}
                {/*>*/}
                {/*  для ЗСУ*/}
                {/*</div>*/}
                <div
                  className={`${globalStyles.text16W} ${styles.header_text16B}`}
                >
                  {t('banner_6_text')}
                </div>
              </div>

              <div className={` ${styles.btn_to_partner}`}>
                <ButtonToPartner href={'/support'} name={t('support')} />
              </div>
              <div className={` ${styles.btn_about}`}>
                <ButtonDetail href={'/projects'} name={t('more')} />
              </div>
              <img
                className={` ${styles.img_banner6}`}
                src={banner6}
                alt="banner_img"
              />
              <div className={` ${styles.header_banner6}`}></div>
            </div>
          </div>
        </Slider>

        <div className={styles.swipe_wrapper}>
          <SwipeRightIcon />
          <div className={styles.swipeRight_title}>{t('swipe_left')}</div>
        </div>
      </header>
    </Navigation>
  );
};

export default Header;

import { Button, Cicle, ImgMap, styles } from "./ButtonYellowStyle";
import mapImage from "../common/svg/prapor 2.png";
import { Typography } from "@mui/material";


const ButtonYellow = ({ text }) => {
  return (
      <a className={`${styles.btn_topartner} ${styles._button}`}  href={"/support"}>
            <Button>
              <Cicle>
                <ImgMap src={mapImage} alt={mapImage} />
              </Cicle>
              <Typography sx={styles.text}>{text}</Typography>
            </Button>
      </a>
  );
};

export default ButtonYellow;

import styles from './aboutFund.module.css';
import globalStyles from '../../stylesGlobal/globalStyles.module.css';
import ButtonWithArrow from './ButtonWithArrow';
import Img1 from '../../common/img/Fund_img1.png';
import Img2 from '../../common/img/Fund_img2.png';
import { useTranslation } from 'react-i18next';

const AboutFund = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.for_what}>
      <div className={styles.container}>
        <div className={`${globalStyles.about_block_text}`}>
          {t('about_fond')}
        </div>
        <div className={`${globalStyles.title} ${styles._title}`}>
          {t('about_fund_1')} -{' '}
          <span className={`${styles.title_color}`}>{t('about_fund_2')}</span>
        </div>
        <div className={styles.block_wraper}>
          <div className={styles.block_left}>
            <div className={`${globalStyles.text18B} ${styles._text18B}`}>
              {t('about_fund_3')}
            </div>
            <div className={styles.content_wraper}>
              <div className={styles.block_text}>
                <li className={`${globalStyles.text12Fw4}`}>{t('if_you_1')}</li>
                <li
                  className={`${globalStyles.text12Fw4} ${styles._text12Fw4}`}
                >
                  {t('if_you_2')}
                </li>
              </div>
              <div className={styles.block_text}>
                <li className={`${globalStyles.text12Fw4}`}>{t('if_you_3')}</li>
                <li
                  className={`${globalStyles.text12Fw4} ${styles._text12Fw4}`}
                >
                  {t('if_you_4')}
                </li>
              </div>
            </div>
            <ButtonWithArrow />
          </div>
          <div className={styles.block_right}>
            {/* <div className={styles.img_foto}></div> */}

            <img
              className={`${styles.img_foto1} ${styles.block_right_img}`}
              src={Img1}
              alt=""
            />
            <img
              className={`${styles.img_fotow} ${styles.block_right_img}`}
              src={Img2}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutFund;

import Slider from 'react-slick';
import ButtonSliderLeft from './ButtonSliderLeft';
import ButtonSliderRight from './ButtonSliderRight';
import styles from './talkaboutUs.module.css';
import globalStyles from '../../../stylesGlobal/globalStyles.module.css';
import ButtonYellow from '../ButtonYellow';
import videoImg1 from '../../../common/svg/video1.png';
import videoImg2 from '../../../common/svg/video2.png';
import videoImg3 from '../../../common/svg/Video3.png';
import videoImg4 from '../../../common/svg/Video4.png';
import ArrowIcon from '../../../common/svg/arrow.svg';
import { useTranslation } from 'react-i18next';

const TalkAboutUs = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <ButtonSliderLeft />,
    prevArrow: <ButtonSliderRight />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={`${styles.section_wraper}`}>
          <div className={styles.block_left}>
            <div className={`${globalStyles.title} ${styles._title}`}>
              {t('media_presence')}
            </div>
            <div className={`${globalStyles.text12Fw4} ${styles.subtitle}`}>
              {t('media_text')}
            </div>

            <div className={`${styles.block_button} ${styles.button_top}`}>
              <ButtonYellow />
            </div>
          </div>

          <div className={styles.block_right}>
            <Slider {...settings}>
              <div>
                <div className={styles.video_content}>
                  <div
                    className={styles.img_content}
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/watch?v=jZUv2aDQiRA&ab_channel=%D0%92%D0%86%D0%94%D0%9A%D0%A0%D0%98%D0%A2%D0%98%D0%99',
                        '_blank'
                      )
                    }
                  >
                    <img
                      className={styles.img_content_img}
                      src={videoImg1}
                      alt=""
                    />
                    <div className={` ${styles.round_button}`}>
                      <img
                        className={styles.round_button_img}
                        src={ArrowIcon}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className={`${globalStyles.text14B} ${styles._text14B}`}>
                    Інтерв’ю з “Відкритого1”
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.video_content}>
                  <div
                    className={styles.img_content}
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/watch?v=x668jCagwS4',
                        '_blank'
                      )
                    }
                  >
                    <img
                      className={styles.img_content_img}
                      src={videoImg2}
                      alt=""
                    />
                    <div className={` ${styles.round_button}`}>
                      <img
                        className={styles.round_button_img}
                        src={ArrowIcon}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className={`${globalStyles.text14B} ${styles._text14B}`}>
                    З 25.02 в новинах Відкритого про нас
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.video_content}>
                  <div
                    className={styles.img_content}
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/watch?v=wbPOwxrLK9I&ab_channel=%D0%92%D1%96%D1%82%D0%B0%D0%9A%D0%BE%D0%BD%D0%B4%D1%80%D0%B0%D1%82%D0%B5%D0%BD%D0%BA%D0%BE',
                        '_blank'
                      )
                    }
                  >
                    <img
                      className={styles.img_content_img}
                      src={videoImg3}
                      alt=""
                    />
                    <div className={` ${styles.round_button}`}>
                      <img
                        className={styles.round_button_img}
                        src={ArrowIcon}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className={`${globalStyles.text14B} ${styles._text14B}`}>
                    Інтерв ю з концерта у Вінниці , Журналісти Ярослав і Віта
                    Кондратенко. Інтернет-ресурси "Крок у Рок" та "Слухай
                    Краще". Радіо "Місто над Бугом"
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.video_content}>
                  <div
                    className={styles.img_content}
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/watch?v=fOL1H-xbcR4&ab_channel=7%D0%BA%D0%B0%D0%BD%D0%B0%D0%BB',
                        '_blank'
                      )
                    }
                  >
                    <img
                      className={styles.img_content_img}
                      src={videoImg4}
                      alt=""
                    />
                    <div className={` ${styles.round_button}`}>
                      <img
                        className={styles.round_button_img}
                        src={ArrowIcon}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className={`${globalStyles.text14B} ${styles._text14B}`}>
                    Інтерв ю з Одеси, Репортаж, 7 канал
                  </div>
                </div>
              </div>
            </Slider>
            <div className={`${styles.block_button} ${styles.button_bottom}`}>
              <ButtonYellow />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TalkAboutUs;

import youTubeImg from '../../common/svg/youtube.png';
import styles from './TalkAboutUs/talkaboutUs.module.css';
import globalStyles from '../../stylesGlobal/globalStyles.module.css';
import { useTranslation } from 'react-i18next';

const ButtonYellow = () => {
  const { t } = useTranslation();
  function openYouTubeChannel() {
    window.open('https://www.youtube.com/@DlyaHeroiv', '_blank');
  }
  return (
    <button className={styles.button} onClick={openYouTubeChannel}>
      <div className={styles.cicle}>
        <img className={styles.circle_img} src={youTubeImg} alt="icon" />
      </div>
      <div className={`${globalStyles.text14B} ${styles.text_name}`}>
        {t('all_videos')}
      </div>
    </button>
  );
};

export default ButtonYellow;

import globalStyles from "../../stylesGlobal/globalStyles.module.css";
import styles from "./payInfo.module.css";
import mono_white from "../../common/svg/mono_white.png";
import { useTranslation } from 'react-i18next';

const ButtonMono = ({ href }) => {
    const {t} = useTranslation();
  return (
    <>
      <a href={href} target="_blank" className={`${styles.button_link}`}>
        <span className={`${styles.round_bg_mono}`}>
          <img className={styles.round_bg_mono_img} src={mono_white} alt="svg" />
          
        </span>
        <span className={`${globalStyles.text14Fw4} ${styles.text14Fw4_mono}`}>{t("lint_to_the_jar")}</span>
      </a>
    </>
  );
};

export default ButtonMono;

import AboutUsMain from './ApoutUsMain';
import ForWhat from './ForWhat';
import OurTeam from './OurTeam';
import HistoryFund from './HistoryFund';
import TalkAboutUs from './TalkAboutUs';
import Certificates from './Сertificates';
import PayInfo from '../PayInfo/PayInfo';

export default () => (
  <div>
    <AboutUsMain />
    <ForWhat />
    <OurTeam />
    <HistoryFund />
    <TalkAboutUs />
    <Certificates />
    <PayInfo/>
  </div>
);

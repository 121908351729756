import { useTranslation, Trans } from 'react-i18next';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Partner from './pages/Partner';
import Support from './pages/Support';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/about_fund',
    element: <About />,
  },
  {
    path: '/projects',
    element: <Projects />,
  },
  {
    path: '/become_partner',
    element: <Partner />,
  },
  {
    path: '/support',
    element: <Support />,
  },
  {
    path: '/uk/',
    element: <Home />,
  },
  {
    path: '/uk/about_fund',
    element: <About />,
  },
  {
    path: '/uk/projects',
    element: <Projects />,
  },
  {
    path: '/uk/become_partner',
    element: <Partner />,
  },
  {
    path: '/uk/support',
    element: <Support />,
  },
  {
    path: '/uk/',
    element: <Home />,
  },
  {
    path: '/en/about_fund',
    element: <About />,
  },
  {
    path: '/en/projects',
    element: <Projects />,
  },
  {
    path: '/en/become_partner',
    element: <Partner />,
  },
  {
    path: '/en/support',
    element: <Support />,
  },
]);

function App() {
  const { t } = useTranslation();

  return <RouterProvider router={router} />;
}

export default App;

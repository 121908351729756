import styles from "./thankesPartners.module.css";
import globalStyles from "../../stylesGlobal/globalStyles.module.css";
import CopyImg from "../../common/svg/copy_black.png";
import { useTranslation } from 'react-i18next';
import {copyOnClick} from "../../common/CopyOnClick";

const ThankesPartners = () => {
  const {t} = useTranslation();
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={`${globalStyles.about_block_text}`}>
          {t("thanks_1")}
        </div>
        <div className={`${styles.title_wraper}`}>
          <div className={`${globalStyles.title} ${styles._title}`}>
            <span className={`${styles.title_color}`}>{t("thanks_2")}</span> {t("thanks_3")}
          </div>
          <div className={styles.btn_wraper}>
            <div className={` ${styles.button_thank}`}>
              <div className={`${globalStyles.text14Fw4} ${styles._text14Fw4}`}>
                {t("th_option_1")}
              </div>
            </div>
            <div className={` ${styles.button_thank}`}>
              <div className={`${globalStyles.text14Fw4} ${styles._text14Fw4}`}>
                {t("th_option_2")}
              </div>
            </div>
            <div className={` ${styles.button_thank}`}>
              <div className={`${globalStyles.text14Fw4} ${styles._text14Fw4}`}>
                {t("th_option_3")}
              </div>
            </div>
            <div className={` ${styles.button_thank}`}>
              <div className={`${globalStyles.text14Fw4} ${styles._text14Fw4}`}>
                {t("th_option_4")}
              </div>
            </div>
          </div>
        </div>
        <div className={` ${styles.block_email}`}>
          <div className={` ${styles.text_email}`}>
            {t("send_letter")}
          </div>
          <div className={` ${styles.button_copy}`}>
            dlya.heroiv.ukraine@gmail.com
            <div className={` ${styles.copy_button_1}`} onClick={() => copyOnClick('dlya.heroiv.ukraine@gmail.com')}>
              <img src={CopyImg} alt="" />
            </div>
          </div>
          {/*<div className={` ${styles.button_copy}`}>*/}
          {/*  <div className={`${globalStyles.text14Fw4}`}>*/}
          {/*    dlya.heroiv.ukraine@gmail.com*/}
          {/*    <div className={` ${styles.round_bg}`}>*/}
          {/*      <img className={styles.round_bg_img} src={CopyImg} alt="" />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </section>
  );
};

export default ThankesPartners;

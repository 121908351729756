import React, { useState, useEffect } from 'react';
import styles from './navMenu.module.css';
import LogoIcon from '../../../common/svg/Group.png';
import TwitterIcon from '../../../ui/icons/TwitterIcon';
import FacebookIcon from '../../../common/svg/Social/Facebook.svg';
import InstagramIcon from '../../../common/svg/Social/Instagram.png';
import YoutubeIcon from '../../../common/svg/Social/YouTube.png';
import TiktokIcon from '../../../common/svg/Social/Tiktok.png';
import web from '../../../common/svg/www.svg';
import HeaderFlagCicle from '../../../ui/HeaderFlagCicle';
import BurgerButton from './BurgerButton';
import ButtonToPartner from './ButtonDetail';
// import Image from 'next/image';
import { useLocation, useNavigate } from 'react-router-dom';
// import a from "next/a";
import { useTranslation } from 'react-i18next';
// import {useRouter} from "next/router";
import i18next from 'i18next';

const getUrlLocale = () => {
  const urlSegments = window.location.pathname.split('/');

  return urlSegments[1].length === 2 ? `/${urlSegments[1]}` : '';
};

const NavMenu = ({ isNavActive, handleNavClick }) => {
  function removeStylesFromBody() {
    var body = document.body;
    body.style.height = '';
    body.style.overflow = '';
  }
  const { t } = useTranslation();

  let location = useLocation();

  const route = location.pathname;

  const lng = getUrlLocale();
  const [locale, setLocale] = useState(i18next.language);
  const changeLanguage = (newLocale) => {
    setLocale(newLocale);
    i18next.changeLanguage(newLocale);
  };

  useEffect(() => {
    document.title = t('title');
  }, [t]);

  return (
    <nav className={styles.nav_menu}>
      <div className={styles.container}>
        <div
          className={`${styles.header_row} ${
            isNavActive ? styles.mobile_header_row : ''
          }`}
        >
          <div className={styles.header_logo}>
            <a href="/">
              <img className={styles.img_logo} src={LogoIcon} alt="Logo" />
            </a>
          </div>
          <div
            className={`${styles.header_menu} ${
              isNavActive ? styles.activ : ''
            }`}
          >
            <div className={`${styles.header_nav} `}>
              <a href={`${lng}/about_fund`}>
                <div className={styles.header_nav_text}>{t('about_fond')}</div>
              </a>
              <a href="/projects">
                <div className={styles.header_nav_text}>{t('projects')}</div>
              </a>
              {/*<a href="/become_partner"><div className={styles.header_nav_text}>Звітність</div></a>*/}
              <a href="/become_partner">
                <div className={styles.header_nav_text}>
                  {t('become_a_partner')}
                </div>
              </a>
            </div>
            <div className={styles.vertical_line}></div>
            <div className={styles.header_support}>
              <HeaderFlagCicle />
              <a href="/support">
                <div
                  className={`${styles.header_nav_text} ${styles.color_text}`}
                >
                  {t('support')}
                </div>
              </a>
            </div>

            <div className={styles.vertical_line} />
            <div className={styles.header_social}>
              {/* <a
                href="#"
                className={`${'styles.icon_social'} a`}
                onClick={() =>
                  window.open(
                    'https://www.tiktok.com/@dlyaheroivukraine?_t=8lBnKLHYiAA&_r=1',
                    '_blank'
                  )
                }
              >
                <TwitterIcon />
              </a> */}
              <a className={'a'} href="#">
                <img
                  className={styles.header_social_img}
                  src={FacebookIcon}
                  alt=""
                  onClick={() =>
                    window.open(
                      'https://www.facebook.com/DlyaHeroivUkraine/',
                      '_blank'
                    )
                  }
                />
              </a>
              <a className={'a'} href="#">
                <img
                  className={styles.header_social_img}
                  src={InstagramIcon}
                  alt=""
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/dlyaheroiv/?hl=en',
                      '_blank'
                    )
                  }
                />
              </a>
              <a className={'a'} href="#">
                <img
                  className={styles.header_social_img}
                  src={YoutubeIcon}
                  alt=""
                  onClick={() =>
                    window.open('https://www.youtube.com/@DlyaHeroiv', '_blank')
                  }
                />
              </a>
              <a className={'a'} href="#">
                <img
                  className={styles.header_social_img}
                  src={TiktokIcon}
                  alt=""
                  onClick={() =>
                    window.open(
                      'https://www.facebook.com/DlyaHeroivUkraine/',
                      '_blank'
                    )
                  }
                />
              </a>
            </div>
            <div className={styles.vertical_line}></div>

            <div className={styles.header_web}>
              <div
                locale={locale}
                onClick={() => changeLanguage(locale === 'en' ? 'uk' : 'en')}
              >
                <img className={styles.hedaer_web_img} src={web} alt="" />
              </div>
            </div>
            <div className={styles.burger_wrapper}>
              <BurgerButton handleNavClick={handleNavClick} />
            </div>
          </div>
        </div>
      </div>
      {/* Burger Menu */}
      <div
        className={`${styles.mobile_menu} ${isNavActive ? styles.active2 : ''}`}
      >
        <div className={`${styles.wraper_header_nav2} `}>
          <div className={`${styles.header_nav2} `}>
            <a onClick={removeStylesFromBody} href="/about_fund">
              <div className={styles.header_nav_text}>{t('about_fond')}</div>
            </a>
            <a onClick={removeStylesFromBody} href="/projects">
              <div className={styles.header_nav_text}>{t('projects')}</div>
            </a>
            {/*<a href="/become_partner"><div className={styles.header_nav_text}>Звітність</div></a>*/}
            <a onClick={removeStylesFromBody} href="/become_partner">
              <div className={styles.header_nav_text}>
                {t('become_a_partner')}
              </div>
            </a>
          </div>
          <div className={`${styles.btn_active} `}>
            <ButtonToPartner name={t('support')} />
          </div>

          <div className={styles.language_burger}>
            <div onClick={() => changeLanguage('uk')}>UA</div>
            <div onClick={() => changeLanguage('en')}>EN</div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavMenu;

import ButtonYellow from '../../ui/ButtonYellow';
import { Typography, Box } from '@mui/material';
import { Section, Span, Cicle, Img, styles } from './OurActivitiesStyle';

import mapImage from '../../common/svg/prapor 2.png';
import { Container } from '../../stylesGlobal/Container';
import { useTranslation } from 'react-i18next';

const OurActivities = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Section>
        <Box sx={{ width: { xs: '335px', sm: '100%' }, mr: 0 }}>
          <Typography sx={styles.subtitle}>{t('directions')}</Typography>
          <Typography variant="h2" sx={styles.title}>
            {t('directions_text_1')} <br />{' '}
            <Span>{t('directions_text_2')}</Span>
            {t('directions_text_3')}
          </Typography>
          {/* <ButtonYellow text="Хочу підтримати" /> */}
        </Box>

        <Box sx={{ mt: { xs: '22px', md: 0.5 } }}>
          <Box sx={{ display: { xs: 'block', lg: 'flex' } }}>
            <Box sx={{ ...styles.button, ...styles.button1 }}>
              <Typography>{t('act_1')}</Typography>
              <Cicle>
                <img src={mapImage} alt={mapImage} />
              </Cicle>
            </Box>

            <Box sx={{ ...styles.button, ...styles.button2 }}>
              <Typography sx={{ pr: 8 }}>{t('act_2')}</Typography>
              <Cicle>
                <img src={mapImage} alt={mapImage} />
              </Cicle>
            </Box>
          </Box>
          <Box sx={{ ...styles.button, ...styles.button3 }}>
            <Typography sx={{ pr: 8 }}>{t('act_3')}</Typography>
            <Cicle>
              <img src={mapImage} alt={mapImage} />
            </Cicle>
          </Box>
          <Box sx={{ ...styles.button, ...styles.button4 }}>
            <Typography sx={{ pr: 8 }}>{t('act_4')}</Typography>
            <Cicle>
              <img src={mapImage} alt={mapImage} />
            </Cicle>
          </Box>
        </Box>
      </Section>
      <ButtonYellow text={t('whant_to_support')} />
    </Container>
  );
};

export default OurActivities;

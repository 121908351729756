import React, { useState } from "react";
import ButtonCoffee from "./ButtonCoffee";
import styles from "./payInfo.module.css";
import Avatar1 from "../../common/svg/Roman.png";
import Avatar2 from "../../common/svg/Volodymir.png";
import paypalSvg from "../../common/svg/paypal.png";
import bitcoinSvg from "../../common/svg/bitcoin-logo-svgrepo-com.svg";
import FormUAH from "./FormUAH";
import FormUSD from "./FormUSD";
import FormMono from "./FormMono";
import FormPaypal from "./FormPaypal";
import FormCrypto from "./FormCrypto";
import { useTranslation } from 'react-i18next';

const PayInfo = () => {
  const {t} = useTranslation();
  const [selected, setSelected] = useState(0);
  return (
    <section className={styles.payInfo}>
      <div className={styles.container}>
        <div className={styles.payInfo_wraper}>
          <div className={styles.left_block}>
            <h2 className={styles.left_block_h2}>{t("payinfo_text_1")}</h2>
            <p className={styles.left_block_p}>
              {t("payinfo_text_2")}
            </p>

            <h5>Buy Me A Coffee:</h5>
            <div className={styles.btns_wrapper}>
              <ButtonCoffee name="Roman" avatar={Avatar1} />
              <ButtonCoffee name="Volodymyr" avatar={Avatar2} />
            </div>
          </div>
          <div className={styles.right_block}>
            <div className={styles.payInfo_requisites}>
              <div className={styles.border_logo}
                   style={{ backgroundColor: selected === 0 ? 'black' : 'white', color: selected === 0 ? 'white': 'black'}}
                   onClick={() => setSelected(0)}>
                <div className={styles.name_method}><span className={styles.name_method_span}>{t("rekvizyty")}</span> UAH</div>
              </div>
              <div className={styles.border_logo}
                   style={{ backgroundColor: selected === 1 ? 'black' : 'white', color: selected === 1 ? 'white': 'black'}}
                   onClick={() => setSelected(1)}>
                <div className={styles.name_method}><span  className={styles.name_method_span}>{t("rekvizyty")}</span> USD</div>
              </div>
              <div className={styles.border_logo}
                   style={{ backgroundColor: selected === 2 ? 'black' : 'white', color: selected === 2 ? 'white': 'black'}}
                   onClick={() => setSelected(2)}>
                <div className={styles.name_method}>MONO</div>
              </div>
              <div className={styles.border_logo}
                   style={{ backgroundColor: selected === 3 ? 'black' : 'white', color: selected === 3 ? 'white': 'black'}}
                   onClick={() => setSelected(3)}>
                <img className={styles.paypal_img}
                  src={paypalSvg}
                  alt="paypalimg"
                />
              </div>
              <div className={styles.border_logo}
                   style={{ backgroundColor: selected === 4 ? 'black' : 'white', color: selected === 4 ? 'white': 'black'}}
                   onClick={() => setSelected(4)}>
                <img className={styles.bitcoin_img}
                  src={bitcoinSvg}
                  alt="bitcoin img"
                />
              </div>
            </div>
            {selected === 0 && <FormUAH />}
            {selected === 1 && <FormUSD />}
            {selected === 2 && <FormMono/>}
            {selected === 3 && <FormPaypal/>}
            {selected === 4 && <FormCrypto/>}
          </div>
        </div>
      </div>
      <div className={styles.bottom_line_img}>
        
      </div>
      

    </section>
  );
};

export default PayInfo;

import globalStyles from '../../stylesGlobal/globalStyles.module.css';
import styles from './ButtonToPartner.module.css';
import mapimg from '../../common/svg/prapor 2.png';
import { useTranslation } from 'react-i18next';

const ButtonToPartner = () => {
  const { t } = useTranslation();
  return (
    <a href="/become_partner">
      <button className={`${styles.btn_topartner} ${styles._button}`}>
        <div className={`${styles.round_bg}`}>
          <img className={styles.round_bg_img} src={mapimg} alt="svg" />
        </div>
        <span className={`${globalStyles.text14Fw4} ${styles.text14Fw4_btn}`}>
          {t('become_a_partner')}
        </span>
      </button>
    </a>
  );
};

export default ButtonToPartner;

import styles from './CharityProjects.module.css';
import globalStyles from '../../stylesGlobal/globalStyles.module.css';
import ButtonToPartner from './ButtonToPartner';
import ButtonFoto from './ButtonFoto';
import CharityProjectsMobile from './CharityProjectsMobile';
import { useEffect, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CharityProjects = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Run the handler once immediately to set initial state
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <section className={styles.projects}>
      <div className={styles.container}>
        <div className={`${globalStyles.title} ${styles.charity_title}`}>
          {t('charity_text_1')}
        </div>
        <div className={`${globalStyles.text14B} ${styles._text14B}`}>
          {t('charity_text_2')}
        </div>
        {!isMobile ? (
          <>
            <div className={` ${styles.charity_item}`}>
              <div className={` ${styles.charity_left}`}>
                <div className={`${globalStyles.title} ${styles.title_item}`}>
                  {t('charity_project_1_header')}
                </div>
                <div className={` ${styles.btn_wrapper}`}>
                  <ButtonToPartner />
                  {/* <ButtonFoto /> */}
                  <div className={` ${styles.btn_wrapper}`}></div>
                </div>
              </div>
              <div className={` ${styles.charity_right}`}>
                <div
                  className={`${globalStyles.text12Fw4} ${styles._text12Fw4}`}
                >
                  {t('charity_project_1_description')}
                </div>
              </div>
            </div>
            <div className={` ${styles.horizontal_line}`}></div>
            <div className={` ${styles.charity_item}`}>
              <div className={` ${styles.charity_left}`}>
                <div className={`${globalStyles.title} ${styles.title_item_2}`}>
                  {t('charity_project_2_header')}
                </div>

                <div className={` ${styles.btn_wrapper}`}>
                  <ButtonToPartner />
                  {/* <ButtonFoto /> */}
                  <div className={` ${styles.btn_wrapper}`}></div>
                </div>
              </div>
              <div className={` ${styles.charity_right}`}>
                <div
                  className={`${globalStyles.text12Fw4} ${styles.two_text12Fw4}`}
                >
                  {t('charity_project_2_description')}
                </div>
              </div>
            </div>
            <div className={` ${styles.horizontal_line}`}></div>
            <div className={` ${styles.charity_item}`}>
              <div className={` ${styles.charity_left}`}>
                <div className={`${globalStyles.title} ${styles.title_item}`}>
                  {t('charity_project_3_header')}
                </div>
                <div className={` ${styles.btn_wrapper}`}>
                  <ButtonToPartner />
                  {/* <ButtonFoto /> */}
                  <div className={` ${styles.btn_wrapper}`}></div>
                </div>
              </div>
              <div className={` ${styles.charity_right}`}>
                <div
                  className={`${globalStyles.text12Fw4} ${styles._text12Fw4}`}
                >
                  {t('charity_project_3_description')}
                </div>
              </div>
            </div>
            <div className={` ${styles.horizontal_line}`}></div>
            <div className={` ${styles.charity_item}`}>
              <div className={` ${styles.charity_left}`}>
                <div className={`${globalStyles.title} ${styles.title_item}`}>
                  {t('charity_project_4_header')}
                </div>
                <div className={` ${styles.btn_wrapper}`}>
                  <ButtonToPartner />
                  {/* <ButtonFoto /> */}
                  <div className={` ${styles.btn_wrapper}`}></div>
                </div>
              </div>
              <div className={` ${styles.charity_right}`}>
                <div
                  className={`${globalStyles.text12Fw4} ${styles._text12Fw4}`}
                >
                  {t('charity_project_4_description')}
                </div>
              </div>
            </div>
            <div className={` ${styles.horizontal_line}`}></div>
          </>
        ) : (
          <CharityProjectsMobile />
        )}
      </div>
    </section>
  );
};

export default CharityProjects;

import { useTranslation } from 'react-i18next';
// import { useRouter } from "next/router";
import styles from "./Header/Header.module.css";
import NavMenu from "./Header/NavMenu";
import HeaderBgIcon from "../../ui/icons/HeaderBgIcon";
import {useState} from "react";

export default function Navigation({ children }) {
  const t = useTranslation();
  // const locale, locales = [], route;
  // const otherLocale = locales?.find((cur) => cur !== locale);
    const [isNavActive, setIsNavActive] = useState(false);

    const handleNavClick = () => {
        setIsNavActive(!isNavActive);

    };

  return (
    <div style={{ position: 'relative'}}>
      <div className={` ${styles.nav_Menu}`}>
        <NavMenu isNavActive={isNavActive} handleNavClick={handleNavClick} />
      </div>

      <div className={` ${styles.header_icon}`}>
        <HeaderBgIcon />
      </div>
      {children}
    </div>
  );
}

import Slider from 'react-slick';
import styles from './certificates.module.css';
import globalStyles from '../../stylesGlobal/globalStyles.module.css';
import ButtonSliderLeft from './ButtonSliderLeft';
import ButtonSliderRight from './ButtonSliderRight';
import SwipeRightIcon from '../../ui/icons/SwipeRightIcon';
import certImg1 from '../../common/svg/Сertificates/1.png';
import certImg2 from '../../common/svg/Сertificates/2.png';
import certImg3 from '../../common/svg/Сertificates/3.png';
import certImg4 from '../../common/svg/Сertificates/4.png';
import { useTranslation } from 'react-i18next';

const Сertificates = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ButtonSliderLeft />,
    prevArrow: <ButtonSliderRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 764,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.block_title}>
          <div className={`${globalStyles.title} ${styles._title}`}>
            <span className={`${styles.title_color}`}>{t('proud_1')}</span>{' '}
            {t('proud_2')}
          </div>
        </div>

        <div className={styles.block_certificates}>
          <Slider {...settings}>
            <div className={styles.slide}>
              <div className={styles.border_logo}>
                <img
                  className={styles.border_logo_img}
                  src={certImg1}
                  alt="img"
                />
              </div>
            </div>

            <div className={styles.slide}>
              <div className={styles.border_logo}>
                <img
                  className={styles.border_logo_img}
                  src={certImg2}
                  alt="img"
                />
              </div>
            </div>
            <div className={styles.slide}>
              <div className={styles.border_logo}>
                <img
                  className={styles.border_logo_img}
                  src={certImg3}
                  alt="img"
                />
              </div>
            </div>
            <div className={styles.slide}>
              <div className={styles.border_logo}>
                <img
                  className={styles.border_logo_img}
                  src={certImg4}
                  alt="img"
                />
              </div>
            </div>
          </Slider>
        </div>
        <div className={styles.swipe_wrapper}>
          <SwipeRightIcon />
          <div className={styles.swipeRight_title}>{t('swipe_left')}</div>
        </div>
      </div>
    </section>
  );
};

export default Сertificates;

import { Container } from '../../stylesGlobal/Container';
import style from './ourValues.module.css';
import FlagIcon from '../../ui/icons/FlagIcon';
import SwipeRightIcon from '../../ui/icons/SwipeRightIcon';
import { useTranslation } from 'react-i18next';

const OurValues = () => {
  const { t } = useTranslation();
  const styles = {
    containerLg: {
      '&.MuiContainer-maxWidthLg': {
        width: '100% ',
      },
    },
  };
  return (
    <Container>
      <section className={style.section}>
        <p className={style.title}>{t('our_values')}</p>
        <div className={style.values}>
          <FlagIcon />
          <p className={style.values_title}>{t('value_1')}</p>
          <FlagIcon />
          <p className={style.values_title}>{t('value_2')}</p>
          <FlagIcon />
          <p className={style.values_title}>{t('value_3')}</p>
          <FlagIcon />
          <p className={style.values_title}>{t('value_4')}</p>
          <FlagIcon />
          <p className={style.values_title}>{t('value_5')}</p>
        </div>
        <div className={style.swipe_wrapper}>
          <SwipeRightIcon />
          <div className={style.swipeRight_title}>{t('swipe_left')}</div>
        </div>
      </section>
    </Container>
  );
};

export default OurValues;

import globalStyles from "../../stylesGlobal/globalStyles.module.css";
import styles from "./ButtonToPartner.module.css";
import mapimg from "../../common/svg/prapor 2.png";
import { useTranslation } from 'react-i18next';

const ButtonFoto = () => {
    const {t} = useTranslation();
  return (
    <button className={`${styles.btn_foto} ${styles._button}`} >
      <div className={`${styles.round_bg_black}`}>
        <img className={styles.round_bg_black_img} src={mapimg} alt="svg" />
      </div>
      <span className={`${globalStyles.text14Fw4} ${styles.text_btn_foto}`}>
        {t("photos")}
      </span>
    </button>
  );
};

export default ButtonFoto;

import React from "react";
import styles from "./afterVictory.module.css";
import globalStyles from "../../stylesGlobal/globalStyles.module.css";
import { useTranslation } from 'react-i18next';

const AfterVictory = () => {
  const {t} = useTranslation();
  return (
    <section className={styles.afterVictory}>
      
        <div className={`${styles.victory_wrapper}`}>
          <div className={`${styles.block_left}`}>
            <div className={`${globalStyles.text14B}  ${styles.top_text}`}>
              {t("after_victory_1")}
            </div>
            <div className={`${globalStyles.title} ${styles.victory_title}`}>
              {t("after_victory_2")}
            </div>
            <div className={`${globalStyles.text12Fw4}  ${styles.top_text12Fw4}`}>
              {t("after_victory_3")}
            </div>
            <div className={`${globalStyles.text14B}  ${styles.bolt_text14B}`}>
              {t("after_victory_4")}
            </div>
            <div className={`${globalStyles.text12Fw4}  ${styles.text12Fw4_3}`}>
              {t("after_victory_5")}
            </div>
          </div>
          <div className={`${styles.block_right}`}></div>
        </div>
      
    </section>
  );
};

export default AfterVictory;

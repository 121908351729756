import styles from "./нeaderFlag.module.css";
import mapImage from "../common/svg/prapor 2.png";
// import Image from 'next/image';

const HeaderFlagCicle = () => {
  return (
    <div className={styles.header_cicle}>
      <img className={styles.header_circle_img} src={mapImage} alt={mapImage} />
    </div>
  );
};

export default HeaderFlagCicle;

import styles from "./forWhat.module.css";
import { useTranslation } from 'react-i18next';

const ForWhat = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.for_what}>
      <div className={styles.container}>
        <div className={styles.subtitle}>{t("about_fond_1")}</div>
        <div className={styles.for_what_wraper}>
          <h2 className={styles.title}>{t("about_fond_2")}</h2>
          <div className={styles.block_text}>
            <p className={styles.p}>
              {t("about_fond_3")}
            </p>
            <div className={styles.text_bottom}>
              {t("about_fond_4")}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForWhat;
